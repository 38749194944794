import Icon from '@pretto/bricks/components/iconography/Icon'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import Link from '@pretto/bricks/website/utility/Link'

import classnames from 'classnames'
import PropTypes from 'prop-types'
import qs from 'qs'
import { useEffect, useState } from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import useAlgolia from '../utilities/useAlgolia'

import * as C from './search.module.css'

const HITS_PER_PAGE = 20

const locationSearchToSearchState = search => {
  const { p: page = 1, s: query } = qs.parse(search, { ignoreQueryPrefix: true })

  return { page, query }
}

const SearchPage = ({ location, navigate }) => {
  const [result, query, setQuery, page, setPage] = useAlgolia(HITS_PER_PAGE)

  const [searchState, setSearchState] = useState(locationSearchToSearchState(location.search))
  const [value, setValue] = useState('')

  useEffect(() => {
    const searchState = locationSearchToSearchState(location.search)

    setSearchState(searchState)
    setValue(searchState.query)
  }, [location.search])

  useEffect(() => {
    setPage(searchState.page)
    setQuery(searchState.query)
  }, [searchState])

  const handleChange = event => setValue(event.target.value)

  const handleSubmit = event => {
    event.preventDefault()
    navigate(`/search/?s=${value}`)
  }

  return (
    <Layout>
      <div className={classnames(C.searchPage)}>
        <header>
          <div className={classnames(C.headerWrapper, C.rowCenter, C.rowPadding)}>
            <p>Votre recherche</p>
            <BaseTypo as="h1" typo="heading1">
              {query}
            </BaseTypo>
          </div>
        </header>

        <div className={classnames(C.searchbar, C.rowCenter, C.rowPadding)}>
          <form role="search" method="get" className={classnames(C.searchForm)} onSubmit={handleSubmit}>
            <Icon className={C.searchIcon} name="search" />

            <input
              className={classnames(C.searchField)}
              name="s"
              onChange={handleChange}
              placeholder="Rechercher..."
              title={`Recherche : ${query}`}
              type="text"
              value={value}
            />

            <div className={classnames(C.searchSubmit)}>
              <Icon name="arrow-right" />
              <input type="submit" value="" />
            </div>
          </form>
        </div>

        <div className={classnames(C.searchResults)}>
          <div className={classnames(C.rowCenter, C.rowPadding)}>
            {result.hits.length === 0 && (
              <div className={classnames(C.noResults)}>
                <p>Aucun résultat pour cette recherche</p>
              </div>
            )}

            {result.hits.map(({ _highlightResult, image, objectID, title, uri }) => (
              <article key={objectID}>
                <div className={classnames(C.articleImage)}>
                  <img
                    src={`https://res.cloudinary.com/pretto-fr/image/upload/c_fill,h_160,w_160,q_auto/${image}`}
                    alt={title}
                  />
                </div>

                <div className={classnames(C.articleContent)}>
                  <BaseTypo as="h2" typo="textLarge">
                    <Link href={uri}>
                      <span dangerouslySetInnerHTML={{ __html: _highlightResult.title.value }} />
                    </Link>
                  </BaseTypo>
                </div>
              </article>
            ))}
          </div>
        </div>

        <div className={classnames(C.searchNav, C.rowCenter, C.rowPadding, C.textPrimary)}>
          {page > 1 && (
            <Link href={`/search/?s=${query}&p=${page - 1}`}>
              <Icon className={classnames(C.searchNavPrevious)} name="arrow-left" />
            </Link>
          )}

          {page < result.nbPages && (
            <Link href={`/search/?s=${query}&p=${page + 1}`}>
              <Icon className={classnames(C.searchNavNext)} name="arrow-right" />
            </Link>
          )}
        </div>
      </div>

      <SEO noindex title={query ? `Vous avez cherché ${query} - Pretto` : 'Cherchez vos mots clés sur Pretto'} />
    </Layout>
  )
}

SearchPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
}

export default SearchPage
