import { useEffect, useState } from 'react'

const DEFAULT_RESULT = {
  hits: [],
  nbPages: 0,
}

const useAlgolia = hitsPerPage => {
  const [cache, setCache] = useState([])
  const [page, setPage] = useState(1)
  const [pending, setPending] = useState([])
  const [query, setQuery] = useState('')
  const [result, setResult] = useState({})

  const fingerprint = [query, page, hitsPerPage].join('')

  useEffect(() => {
    ;(async () => {
      const localFingerprint = fingerprint

      if (query === '' || localFingerprint in cache || pending.includes(localFingerprint)) {
        return
      }

      setPending(pending => [...pending, localFingerprint])

      const body = {
        requests: [
          {
            indexName: 'entries',
            params: `query=${query}&hitsPerPage=${hitsPerPage}&page=${
              page - 1
            }&highlightPreTag=%3Cmark%3E&highlightPostTag=%3C%2Fmark%3E&facets=%5B%5D&tagFilters=`,
          },
        ],
      }

      const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
      const url = `https://${ALGOLIA_APP_ID.toLowerCase()}-dsn.algolia.net/1/indexes/*/queries?x-algolia-application-id=${ALGOLIA_APP_ID}&x-algolia-api-key=${ALGOLIA_SEARCH_API_KEY}`

      const response = await fetch(url, { body: JSON.stringify(body), headers, method: 'POST' })
      const { results } = await response.json()
      const result = results[0]

      setCache(cache => ({ ...cache, [localFingerprint]: result }))
      setPending(pending => pending.filter(pendingQuery => pendingQuery !== localFingerprint))
      setResult(result)
    })()
  }, [page, query])

  return [
    {
      ...DEFAULT_RESULT,
      ...result,
      ...(cache[fingerprint] || {}),
    },
    query,
    setQuery,
    parseInt(page, 10),
    setPage,
  ]
}

export default useAlgolia
