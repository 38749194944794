import { colors } from '@pretto/zen/reveal/theme/colors'

import { getNavbarBackgroundColor } from '@pretto/website/src/lib/getNavbarBackgroundColor'

import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { memo } from 'react'
import Helmet from 'react-helmet'
import url from 'url'

const QUERY = graphql`
  query Seo {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const SEO = ({ canonical, category, date, description, image, modified, noindex, template, title }) => {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(QUERY)

  const navbarColor = colors[getNavbarBackgroundColor(template)]

  const location = useLocation()

  const imageUrl = `https://res.cloudinary.com/pretto-fr/image/upload/${image}`

  const meta = [
    { charset: 'utf-8' },

    {
      content: description,
      name: 'description',
    },

    {
      content: description,
      property: 'og:description',
    },
    {
      content: imageUrl,
      property: 'og:image',
    },
    {
      content: imageUrl,
      property: 'og:image:secure_url',
    },
    {
      content: 'fr_FR',
      property: 'og:locale',
    },
    {
      content: 'Pretto',
      property: 'og:site_name',
    },
    {
      content: title,
      property: 'og:title',
    },
    {
      content: location.pathname === '/' ? 'website' : 'article',
      property: 'og:type',
    },
    {
      content: url.resolve(siteUrl, location.pathname),
      property: 'og:url',
    },

    {
      content: 'summary',
      name: 'twitter:card',
    },
    {
      content: '@pretto',
      name: 'twitter:creator',
    },
    {
      content: description,
      name: 'twitter:description',
    },
    {
      content: imageUrl,
      name: 'twitter:image',
    },
    {
      content: '@pretto',
      name: 'twitter:site',
    },
    {
      content: title,
      name: 'twitter:title',
    },
    {
      content: navbarColor,
      name: 'theme-color',
    },
    {
      content: navbarColor,
      name: 'msapplication-navbutton-color',
    },
  ]

  if (category) {
    meta.push({
      content: category,
      name: 'article:section',
    })
  }

  if (date) {
    meta.push({
      content: date,
      name: 'article:published_time',
    })
  }

  if (modified) {
    meta.push({
      content: modified,
      name: 'article:modified_time',
    })
  }

  if (noindex === 'noindex') {
    meta.push({
      content: 'noindex',
      name: 'robots',
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'fr-FR',
      }}
      title={title}
      link={[
        {
          href: url.resolve(siteUrl, canonical || location.pathname),
          rel: 'canonical',
        },
      ]}
      meta={meta}
    />
  )
}

SEO.defaultProps = {
  image: 'website/highlight/default',
}

SEO.propTypes = {
  canonical: PropTypes.string,
  category: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  modified: PropTypes.string,
  noindex: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default memo(SEO)
